// This component is used in the NovaSidePanel component to represent an item in the side panel. They don't render on their own so
// Axe/unit tests are done at the NovaSidePanel level.
// eslint-disable-next-line nova/component-has-axe-test,nova/component-has-unit-test,nova/component-has-vdiff-test, nova/component-has-demo
import { html, LitElement } from 'lit';
import { NovaPermissionMixin } from '../../../mixins/nova-permission-mixin/nova-permission-mixin.js';

export class NovaSidePanelItemGroup extends NovaPermissionMixin(LitElement) {
  static properties = {
    label: { type: String },
    icon: { type: String },
    expanded: { type: Boolean },
  };

  constructor() {
    super();
    this.expanded = false;
    this.label = '';
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  allowedRender() {
    return html`
      <slot></slot>
    `;
  }
}
customElements.define('nova-side-panel-item-group', NovaSidePanelItemGroup);
