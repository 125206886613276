import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/dialog/dialog-fullscreen.js';
import '@brightspace-ui/core/components/dropdown/dropdown.js';
import '@brightspace-ui/core/components/dropdown/dropdown-content.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/filter/filter.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set-value.js';
import '@brightspace-ui/core/components/filter/filter-tags.js';

import { css, html, LitElement, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../../skills/skill-chip-list/skill-chip-list.js';
import ActivityFilter from '../../../models/activity-filter/activity-filter.js';
import { ActivitySchema } from '../../../../../shared/models/schema/activity/index.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

const mediaQueryList = window.matchMedia('(max-width: 767px)');
const _activeStatePossibleValues = ['Active', 'Inactive'].map(state => ({ displayName: state, value: state.toLowerCase() }));
const FILTER_PAGE_SIZE = 5;

class ActivityFilterComponent extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      filter: { type: Object },
      showActiveFilter: { type: Boolean }, // Show admin filters if this flag is true
      skills: { type: Array },
      disabledFilters: { type: Array, reflect: false },
      careerFilterItems: { type: Array, attribute: 'career-filter-items' },
      saveFilters: { type: Boolean },
      _filtersApplied: { type: Boolean }, // True if any filters are applied
      _filterTagsHeight: { type: String }, // Determines height of filter tags component, E.g. "21px" or "auto"
    };
  }

  static get styles() {
    return [
      super.styles,
      labelStyles,
      css`
        :host {
          width: inherit;
        }

        .filter-collection-container {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
        }

        .primary-filter-container {
          align-items: center;
          border-right: 2px dotted var(--d2l-color-mica);
          column-gap: 0.6rem;
          display: inline-flex;
          justify-content: flex-start;
          padding-right: 0.6rem;
        }

        .primary-filter-container > p {
          flex-shrink: 0;
        }

        .other-filters {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          gap: 0.6rem 0.6rem;
          justify-content: flex-start;
          padding-left: 0.6rem;
          width: calc(100% - 152px);
        }

        .filter-tags-wrapper {
          align-items: flex-end;
          display: flex;
          height: 0;
          overflow: hidden;
          transition: height 0.2s ease-in-out;
        }

        @media (prefers-reduced-motion) {
          .filter-tags-wrapper {
            transition: none;
          }
        }

        @media (max-width: 767px) {
          .filter-template-wrapper {
            display: none;
          }

          .filter-collection-container {
            align-items: center;
          }

          .mobile-clear-container {
            text-align: left;
            width: 100%;
          }

          #mobile-clear {
            margin-left: -0.6rem; /* align text to left, rather than padding */
          }

          .other-filters {
            display: unset;
            padding: unset;
            width: unset;
          }

          .primary-filter-container {
            border-right: unset;
            column-gap: unset;
            padding-right: unset;
          }

          d2l-filter-tags {
            width: 100%;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.filter = new ActivityFilter();
    this.showActiveFilter = false;
    this.careerFilterItems = [];
    this.disabledFilters = [];
    this.saveFilters = true;
    this.manualFilterData = {
      skills: {
        fullData: [],
        hasMore: false,
        loaded: false,
        paged: [],
      },
      careers: {
        fullData: [],
        hasMore: false,
        loaded: false,
        paged: [],
      },
      provider: {
        fullData: [],
        hasMore: false,
        loaded: false,
        paged: [],
      },
      employer: {
        fullData: [],
        hasMore: false,
        loaded: false,
        paged: [],
      },
    };

    this._filterTagsHeight = '0px';
    this._filterTagsObserver = null;
    this._skillFilterItems = [];
    this._providers = [];
    this._employers = [];
    this._handleResize = this._handleResize.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    if (mediaQueryList.addEventListener) mediaQueryList.addEventListener('change', this._handleResize);

    this.filter = new ActivityFilter({ ...this.session.user.getSetting('filters'), hasCareerFilter: this._showCareerExplorer });

    this._showLots = this.session.tenant?.careerExplorer?.settings?.showLots;
    // Prefix used in filter ids; to be prepended to the filter's dimension key to form the id
    const careerFilterKey = this._showLots ? 'lots' : 'jobs';
    this._filterIdPrefix = 'filter-';
    this._filterDimensionKeys = [
      'skills',
      this._showCareerExplorer && careerFilterKey,
      'certificateType',
      'provider',
      'employer',
      'format',
      'duration',
      'weeklyTimeCommitment',
      'instructionLang',
      'activeState',
    ];
    // Form string with all filter ids to use with filter-tags component
    this._filterIdsString = this._filterIdPrefix + this._filterDimensionKeys.join(` ${this._filterIdPrefix}`);
  }

  disconnectedCallback() {
    if (mediaQueryList.removeEventListener) mediaQueryList.removeEventListener('change', this._handleResize);
    if (this._filterTagsObserver) this._filterTagsObserver.disconnect();
    super.disconnectedCallback();
  }

  async firstUpdated() {
    if (this.session.tenant.type === 'provider') {
      this._providers = [this.session.tenant];
    } else {
      try {
        const temp = await this.client.listTenants('provider');
        this._providers = temp.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });

        if (this.session.tenant.type === 'admin') {
          try {
            const tempEmp = await this.client.listTenants('employer');
            this._employers = tempEmp.sort((a, b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
          } catch (err) {
            this._employers = [];
          }
        }

        this.filter = new ActivityFilter({ ...this.filter, validProviders: this._providers, validEmployers: this._employers });
      } catch (err) {
        this._providers = [];
        this._employers = [];
      }
    }

    this._updateOtherFiltersTextCount();
    this._setFilterTagsHeight();
    this._setUpFilterTagsObserver();
    this._setUpManualFilterData('provider');
    this._setUpManualFilterData('employer');
    this._setUpManualFilterData('skills');
  }

  _setUpManualFilterData(type) {
    this.manualFilterData.careers.fullData = this.careerFilterItems ?? [];
    this.manualFilterData.provider.fullData = this._providers ?? [];
    this.manualFilterData.skills.fullData = this.skills ?? [];
    this.manualFilterData.employer.fullData = this._employers ?? [];

    const filterId = type === 'careers' ? this._showLots ? 'lots' : 'jobs' : type;

    this.manualFilterData[type].hasMore = this.manualFilterData[type].fullData.length > FILTER_PAGE_SIZE;
    if (!this.manualFilterData[type].loaded && this.manualFilterData[type].fullData.length > 0) {
      this.manualFilterData[type].paged = this.manualFilterData[type].fullData.filter(item => {
        const id = item.value ?? item.id;
        return this.filter[filterId]?.includes(id);
      });

      this.manualFilterData[type].loaded = true;
    }

    if (this.manualFilterData.skills.loaded) {
      this._updateSkillsFilterItems();
      this.requestUpdate();
    }
  }

  get _filterTagsElement() {
    return this.shadowRoot.querySelector('d2l-filter-tags');
  }

  _setFilterTagsHeight() {
    if (!this._filterTagsElement) return;

    // The +1 px is a buffer for cases where user has zoomed in/ou
    // and bottom gets cut off due to partial px amounts of difference
    // The +18 is extra spacing replacing the CSS margin.
    // The margin was causing animation to be less smooth.
    const margin = 18;
    const newHeight = this._filterTagsElement.offsetHeight > 0
      ? `${this._filterTagsElement.offsetHeight + margin + 1}px`
      : '0px';
    this._filterTagsHeight = newHeight;
  }

  _setUpFilterTagsObserver() {
    if (!this._filterTagsElement || this._filterTagsObserver) return;

    this._filterTagsObserver = new ResizeObserver(() => { this._setFilterTagsHeight(); });
    this._filterTagsObserver.observe(this._filterTagsElement);
  }

  get _prefersReducedMotion() {
    return window.matchMedia('(prefers-reduced-motion: reduce)');
  }

  render() {
    return !mediaQueryList.matches ?
      html`
        <div class="filter-template-wrapper">
          ${this._filterCollectionTemplate}
          ${this._appliedFiltersTemplate}
        </div>`
      : this._mobileTemplate;
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('skills')) {
      if (this.manualFilterData.skills.loaded) {
        this._updateSkillsFilterItems();
      } else {
        this._setUpManualFilterData('skills');
      }
    }
    if (changedProperties.has('careerFilterItems')) {
      this._setUpManualFilterData('careers');
    }
  }

  get _appliedFiltersTemplate() {
    return html`
      <div
        class="filter-tags-wrapper"
        ?filters-applied=${this._filtersApplied}
        style="height: ${this._filterTagsHeight}">
        <d2l-filter-tags
          class="d2l-skeletize"
          filter-ids="${ifDefined(this._filterIdsString)}">
        </d2l-filter-tags>
      </div>
    `;
  }

  get _isSingleCareerSelectable() {
    return this.session.tenant.careerExplorer?.settings?.careerFilterSelectionMode === 'single';
  }

  get _showCareerExplorer() {
    return this.session.tenant.hasTag('careerExplorer') && this.session.tenant.type === 'employer';
  }

  get _careerFilterTemplate() {
    const dimensionKey = this._showLots ? 'lots' : 'jobs';
    const disabled = this._filterIsDisabled(dimensionKey);
    return this._filterDimensionSetTemplate(
      dimensionKey,
      this.manualFilterData.careers.paged,
      'activity-filter.filterType.careerType',
      'manual',
      this._isSingleCareerSelectable,
      this.localize('activity-filter.filterType.careerType.introText'),
      disabled
    );
  }

  get _skillsFilterTemplate() {
    return this._filterDimensionSetTemplate('skills', this.manualFilterData.skills.paged, 'activity-filter.filterType.skillDialog', 'manual');
  }

  get _filterCollectionTemplate() {
    return html`
      <div class="filter-collection-container">
        ${this._primaryFilterTemplate}
        <div class="other-filters">
          ${this._showCareerExplorer ? this._skillsFilterTemplate : nothing}
          ${this._filterDimensionSetTemplate('certificateType', ActivitySchema.getPossibleValues('certificateType'), 'activity-filter.filterType.certificationType')}
          ${this._filterDimensionSetTemplate('provider', this.manualFilterData.provider.paged, 'general.provider', 'manual')}
          ${this._filterDimensionSetTemplate('format', ActivitySchema.getPossibleValues('format'), 'activity-filter.filterType.format')}
          ${this._filterDimensionSetTemplate('duration', ActivitySchema.getPossibleValues('duration'), 'activity-filter.filterType.duration')}
          ${this._filterDimensionSetTemplate('weeklyTimeCommitment', ActivitySchema.getPossibleValues('weeklyTimeCommitment'), 'activity-filter.filterType.weeklyTimeCommitment')}
          ${this._filterDimensionSetTemplate('instructionLang', ActivitySchema.getPossibleValues('instructionLang'), 'activity-filter.filterType.instructionLang')}
          ${this._adminFiltersTemplate('_filterDimensionSetTemplate')}
        </div>
      </div>
    `;
  }

  get _filtersApplied() {
    const numJobsFiltered = this._showLots
      ? this.filter.lots.length
      : this.filter.jobs.length;
    return (this.filter.populatedOthersTypeCount + this.filter.skills.length + numJobsFiltered) > 0;
  }

  get _mobileClearAllTemplate() {
    return html`
      <div class="mobile-clear-container">
        <d2l-button-subtle
          id="mobile-clear"
          @click=${this._handleMobileClearAllFilters}
          text=${this.localize('activity-filter.clearFilters')}
        ></d2l-button-subtle>
      </div>
    `;
  }

  get _mobileTemplate() {
    return html`
      <div class="filter-collection-container">
        ${this._primaryFilterTemplate}
        ${this._otherFiltersTemplate}
      </div>
      ${this._filtersApplied ? this._mobileClearAllTemplate : nothing}
    `;
  }

  get _otherFiltersTemplate() {
    return html`
      <div class="other-filters d2l-skeletize">
        <d2l-filter
          id="other-filters"
          text="${this.localize('activity-filter.mobile.otherFilters')}"
          @d2l-filter-change=${this._dimensionValueChanged}
          >
            ${this._showCareerExplorer ? this._skillsFilterTemplate : nothing}
            ${this._filterDimensionSetTemplate('certificateType', ActivitySchema.getPossibleValues('certificateType'), 'activity-filter.filterType.certificationType')}
            ${this._filterDimensionSetTemplate('provider', this.manualFilterData.provider.paged, 'general.provider')}
            ${this._filterDimensionSetTemplate('format', ActivitySchema.getPossibleValues('format'), 'activity-filter.filterType.format')}
            ${this._filterDimensionSetTemplate('duration', ActivitySchema.getPossibleValues('duration'), 'activity-filter.filterType.duration')}
            ${this._filterDimensionSetTemplate('weeklyTimeCommitment', ActivitySchema.getPossibleValues('weeklyTimeCommitment'), 'activity-filter.filterType.weeklyTimeCommitment')}
            ${this._filterDimensionSetTemplate('instructionLang', ActivitySchema.getPossibleValues('instructionLang'), 'activity-filter.filterType.instructionLang')}
            ${this._adminFiltersTemplate('_filterDimensionSetTemplate')}
        </d2l-filter>
    </div>`;
  }

  get _primaryFilterTemplate() {
    return html`
      <div class="primary-filter-container d2l-skeletize">
        <p class="d2l-label-text">${this.localize('activity-filter.filterBy')}:</p>
        ${this._showCareerExplorer ? this._careerFilterTemplate : this._skillsFilterTemplate}
      </div>
    `;
  }

  // activity filters specific to admin page
  _adminFiltersTemplate(template) {
    if (this.session.tenant.type !== 'admin' || !this.showActiveFilter) return nothing;
    return html`
     ${this[template]('activeState', _activeStatePossibleValues, 'activity-filter.filterType.activeState')}
     ${this[template]('employer', this.manualFilterData.employer.paged, 'general.employer', 'manual', true)}
    `;
  }

  async _dimensionValueChanged(e) {
    e.stopPropagation();
    let skillFilterChanged = false;
    e.detail.dimensions.forEach(dimension => {
      const dimensionKey = dimension.dimensionKey;
      if (dimension.cleared) {
        this.filter[dimensionKey].length = 0;
        return;
      }

      const changedValues = dimension.changes;
      changedValues.forEach(changedValue => {
        const filter = this.filter[dimensionKey];
        if (changedValue.selected) {
          filter.push(changedValue.valueKey);
        } else {
          const index = filter.indexOf(changedValue.valueKey);
          filter.splice(index, 1);
        }

        if (dimensionKey === 'skills' && changedValue.selected) {
          skillFilterChanged = true;
          const skillFilter = this.skills.filter(filterObj => filterObj.id === changedValue.valueKey);
          if (skillFilter.length === 1) {
            const { count: filterCount, name: filterName } = skillFilter[0];
            this.client.logEvent({ eventType: 'skillFilterApplied', filterEmsiId: changedValue.valueKey, filterCount, filterName });
          }

        }
      });
    });

    await this._dispatchFilterEvent();
    this._updateOtherFiltersTextCount();
    // Avoid logging two events for skill filters
    if (!skillFilterChanged) {
      this.client.logEvent({ eventType: 'filterApplied', ...this.filter.eventData });
    }

    this.requestUpdate();
  }

  async _dispatchFilterEvent() {
    if (this.saveFilters) {
      await this.client.setSessionSetting('filters', this.filter);
    }

    const filterChangedEvent = new CustomEvent('filter-changed', {
      detail: { filter: this.filter },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(filterChangedEvent);
  }

  _filterDimensionSetTemplate(id, mapping, translationKeyBase, searchType = 'none', selectionSingle = false, introText, disabled = false) {
    const isPrimaryDimension = this._showCareerExplorer ? ['jobs', 'lots'].includes(id) : id === 'skills';
    const displayText = (id === 'provider' || id === 'employer') ? this.localize(`${translationKeyBase}`) : this.localize(`${translationKeyBase}.displayText`);
    const manualFilterId = this._getFilterId(id);

    const dimensionSet = html`
      <d2l-filter-dimension-set
        key=${id}
        text=${displayText}
        search-type=${searchType}
        ?has-more=${this.manualFilterData[manualFilterId]?.hasMore}
        introductory-text=${ifDefined(introText)}
        ?selection-single=${selectionSingle}>
        ${this._filterSetValuesTemplate(mapping, id)}
      </d2l-filter-dimension-set>
    `;

    return mediaQueryList.matches && !isPrimaryDimension ? dimensionSet : html`
      <div class="d2l-skeletize">
        <d2l-filter
          id="${this._filterIdPrefix}${id}"
          dimension-key="${id}"
          @d2l-filter-change=${this._dimensionValueChanged}
          @d2l-filter-dimension-load-more=${this._handleLoadMore}
          @d2l-filter-dimension-search=${this._handleSearch}
          ?disabled=${disabled}>
          ${dimensionSet}
        </d2l-filter>
      </div>
    `;
  }

  _filterIsDisabled(dimensionKey) {
    return this.disabledFilters.some(filterKey => filterKey === dimensionKey);
  }

  async _handleLoadMore(e) {
    const { key: dimensionKey, loadMoreCompleteCallback, value } = e.detail;
    this._addFilterItems(dimensionKey, FILTER_PAGE_SIZE, false, value);
    this.requestUpdate();
    loadMoreCompleteCallback({ displayAllKeys: true });
  }

  async _handleSearch(e) {
    const { key, searchCompleteCallback, value } = e.detail;
    const manualFilterId = this._getFilterId(key);
    this._addFilterItems(manualFilterId, FILTER_PAGE_SIZE, true, value);
    this.requestUpdate();
    await this.updateComplete;
    const keysToDisplay = this.manualFilterData[manualFilterId].paged.filter(x => !x.hidden).map(x => x.id ?? x.value);
    searchCompleteCallback({ keysToDisplay });
  }

  _getFilterId(dimension) {
    return ['jobs', 'lots'].includes(dimension) ? 'careers' : dimension;
  }

  _addFilterItems(dimension, addCount, clearItems, searchValue = '') {
    const manualFilterId = this._getFilterId(dimension);
    const currentlyVisibleIds = clearItems ? [] : this.manualFilterData[manualFilterId].paged.map(x => x.value ?? x.id);
    let added = 0;
    const updatedItems = this.manualFilterData[manualFilterId].fullData.filter(item => {
      const id = item.value ?? item.id;

      if (this.filter[dimension]?.includes(id) || currentlyVisibleIds.includes(id)) {
        return true;
      }

      if (added >= addCount) {
        this.manualFilterData[manualFilterId].hasMore = true;
        return false;
      }

      if (this._textIsInSearch(searchValue, item.displayName ?? item.name ?? '')) {
        added++;
        this.manualFilterData[manualFilterId].hasMore = false;
        return true;
      }

      return false;
    });

    // Hack to ensure the filter items list is never empty as this causes a bug
    if (updatedItems.length === 0) updatedItems.push({ hidden: true, key: 'hidden', id: 'hidden' });

    this.manualFilterData[manualFilterId].paged = updatedItems;
  }

  _updateSkillsFilterItems() {
    const skillFilter = this.shadowRoot.querySelector('#filter-skills');
    const search = skillFilter?.shadowRoot.querySelector('d2l-input-search')?.value;

    this._addFilterItems('skills', FILTER_PAGE_SIZE, false, search ?? '');
  }

  _textIsInSearch(searchValue, text) {
    return searchValue === '' || text.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
  }

  _filterSetValuesTemplate(options, dimensionKey) {
    return repeat(options, option => (option.value ?? option.id), option => html`
      <d2l-filter-dimension-set-value
        key=${option.value ?? option.id}
        text=${option.displayName ?? option.name}
        count=${ifDefined(option.count)}
        ?selected=${this.filter[dimensionKey].includes(option.value ?? option.id)}>
      </d2l-filter-dimension-set-value>
    `);
  }

  _handleMobileClearAllFilters() {
    const possiblePrimaryFilterDimensions = ['filter-jobs', 'filter-lots', 'filter-skills'];
    const filterIds = [...possiblePrimaryFilterDimensions, 'other-filters'];
    filterIds.forEach(id => {
      this.shadowRoot.querySelector(`#${id}`)?.requestFilterClearAll();
    });
    this._updateOtherFiltersTextCount();
  }

  _handleResize() {
    // handle case where user goes from narrow to wide screens
    // observer only gets set up on wide screens that show activity-tags component
    this._setUpFilterTagsObserver();
    this.requestUpdate();
  }

  _updateOtherFiltersTextCount() {
    if (mediaQueryList.matches) {
      const otherFilters = this.shadowRoot.querySelector('#other-filters');
      const otherFiltersButton = otherFilters.shadowRoot.querySelector('d2l-dropdown-button-subtle');
      const numOtherFiltersSelected = this.filter.populatedOthersCount;
      const filterButtonText = numOtherFiltersSelected > 0
        ? `${otherFilters.getAttribute('text')} (${numOtherFiltersSelected})`
        : otherFilters.getAttribute('text');
      window.requestAnimationFrame(() => {
        if (otherFiltersButton) {
          otherFiltersButton.setAttribute('text', filterButtonText);
        }
      });
    }
  }
}

window.customElements.define('activity-filter', ActivityFilterComponent);
